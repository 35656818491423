function ProductPage($) {
    var self = this;

    /**
     * All the links that will scroll the page
     * @type {jQuery}
     */
    self.$scrollLinks = $('a.scroll-link');

    /**
     * The body that will scroll
     * @type {jQuery}
     */
    self.$scrollBody = $('html, body');

    /**
     * The links/buttons that change an attribute dropdown value
     * @type {jQuery}
     */
    self.$attributeChooserParents = $('div.product-attribute-chooser');

    /**
     * All the attribute dropdowns
     * @type {jQuery}
     */
    self.$attributeDropdowns = $('table.variations').find('select');

    /**
     * The window as a jQuery object, to prevent multiple jQuery calls
     * @type {jQuery}
     */
    self.$window = $(window);

    /**
     * The 'add to cart' section of the product page, and its helper and parent
     * @type {jQuery}
     */
    self.$floatElementParent = $('div.product-add-to-cart');
    self.$floatElementHelper = self.$floatElementParent.find('div.product-add-to-cart-inner-helper');
    self.$floatElement = self.$floatElementParent.find('div.product-add-to-cart-inner');

    /**
     * The left and width properties for the floating element
     * @type {Number}
     */
    self.floatElementLeft = null;
    self.floatElementWidth = null;

    /**
     * The element that helps decide when to make the float-element stop floating
     * @type {jQuery}
     */
    self.$floatReferenceElement = $('div.product-details-inner');

    /**
     * The breakpoints for when to start/stop floating
     * @type {Object}
     */
    self.floatBreakpoints = {
        start: null,
        end: null
    };



    /**
     * Enable the links that scroll the page
     * @return {void}
     */
    self.enableScrollLinks = function() {
        self.$scrollLinks.each(function(index, link) {
            var $link = $(link);

            // Get the target to scroll to
            var targetId = $link.attr('href');
            var $target = $(targetId);

            $link.on('click', function(event) {
                event.preventDefault();

                // Scroll to the target
                self.$scrollBody.animate({
                    // Use 20px headroom
                    scrollTop: $target.offset().top - 20
                }, 300);
            });
        });
    };



    /**
     * Enable the mechanism that changes the attribute dropdown value
     * With a link elsewhere on the page
     * @return {void}
     */
    self.enableChangeAttribute = function() {
        // The attribute buttons change the dropdowns
        self.$attributeChooserParents.each(function(index, parent) {
            var $parent = $(parent);
            var $chooser = $parent.find('a');

            // Get the name, value and the dropdown to control with them
            var name = $chooser.data('attribute-name');
            var value = $chooser.data('attribute-value');
            var $dropdown = self.$attributeDropdowns.filter('select[name="attribute_' + name + '"]');

            $chooser.on('click', function(event) {
                event.preventDefault();

                // Set the 'chosen' CSS class only on the clicked link/button
                self.$attributeChooserParents.removeClass('chosen');
                $parent.addClass('chosen');

                // Change the dropdown value
                $dropdown.val(value);
            });
        });

        // The dropdowns change the attribute buttons
        self.$attributeDropdowns.each(function(index, dropdown) {
            var $dropdown = $(dropdown);

            $dropdown.on('change', function(event) {
                var value = $dropdown.val();

                // Remove the 'chosen' class of all chooser parents
                self.$attributeChooserParents.removeClass('chosen');

                // Set the 'chosen' CSS class on the link/button that corresponds to the selected value
                // But only if a value has been chosen
                if (value !== '') {
                    self.$attributeChooserParents
                        .find('a')
                        .filter('[data-attribute-value="' + value + '"]')
                        .parent('div')
                        .addClass('chosen');
                }
            });
        });
    };



    /**
     * Define the breakpoints for when the floating element needs to start/stop floating
     * @param {Number}  headroom  The headroom for the floating element
     * @return {void}
     */
    self.setFloatStartBreakpoint = function(headroom) {
        // Start floating when the window has scrolled to the floating element (with headroom)
        self.floatBreakpoints.start = self.$floatElementHelper.offset().top - headroom;
    };

    self.setFloatEndBreakpoint = function(headroom) {
        // Calculate the threshold of when to stop floating:
        // Use the bottom pixel offset of the reference element,
        // minus the floating element's height, and the headroom
        var floatReferenceElementBottom = self.$floatReferenceElement.offset().top + self.$floatReferenceElement.outerHeight();
        self.floatBreakpoints.end = floatReferenceElementBottom - self.$floatElement.outerHeight() - headroom;
    };



    /**
     * Update the left and width properties for the floating element
     * @return {void}
     */
    self.setFloatingElementPositionSize = function() {
        self.floatElementLeft = self.$floatElementHelper.offset().left;
        self.floatElementWidth = self.$floatElementHelper.width();

        self.$floatElement.css({
            left: self.floatElementLeft,
            width: self.floatElementWidth
        });
    };



    /**
     * The event handler for the window scrolling, but also called at page load
     * @param  {Object}  css  The CSS to apply to the element(s)
     * @return {void}
     */
    self.changeFloatElementStatus = function(css) {
        var scrolled = self.$window.scrollTop();

        if (scrolled < self.floatBreakpoints.start) {
            // Element is stationary before the start threshold
            self.$floatElement.css(css.stationaryTop);
        } else if (scrolled >= self.floatBreakpoints.start && scrolled <= self.floatBreakpoints.end) {
            // Float the element
            self.$floatElement
                .css(css.floating)
                .css({
                    left: self.floatElementLeft,
                    width: self.floatElementWidth
                });
        } else if (scrolled > self.floatBreakpoints.end) {
            // Element is stationary after the end threshold
            self.$floatElementParent.css('position', 'relative');
            self.$floatElement.css(css.stationaryBottom);
        }
    };



    /**
     * Make the 'add to cart' section float during scroll
     * @return {void}
     */
    self.enableFloatElement = function() {
        self.$window.on('load', function(event) {
            var headroom = 20;

            self.setFloatStartBreakpoint(headroom);

            // Set the float breakpoints at page load, and at a few intervals
            // To allow the whole page to be loaded properly, takes a bit longer sometimes
            self.setFloatEndBreakpoint(headroom);
            var maxTimes = 6;
            var currentCount = 0;
            var intervalLength = 500;
            var settingInterval = setInterval(function() {
                self.setFloatEndBreakpoint(headroom);

                // Stop looping when the max amount of times are reached
                if (++currentCount === maxTimes) {
                    clearInterval(settingInterval);
                }
            }, intervalLength);

            // Set the left position and width for the floating element, and update on resize
            self.setFloatingElementPositionSize();
            self.$window.on('resize', function() {
                self.setFloatingElementPositionSize();
                self.setFloatStartBreakpoint(headroom);
                self.setFloatEndBreakpoint(headroom);
            });

            // Set the CSS properties for the status during scroll
            var css = {
                floating: {
                    position: 'fixed',
                    bottom: 'auto',
                    top: headroom,
                    zIndex: 1
                },
                stationaryTop: {
                    position: 'static'
                },
                stationaryBottom: {
                    position: 'absolute',
                    top: 'auto',
                    bottom: 0,
                    // Keep only the numbers of the 'padding-left' property (remove 'px')
                    left: parseFloat(self.$floatElementParent.css('paddingLeft').replace(/[^\d]/g, ''))
                }
            };

            // Decide when to start/stop floating the element
            self.changeFloatElementStatus(css);
            self.$window.on('scroll', function(event) {
                self.changeFloatElementStatus(css);
            });
        });
    };
}
