/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          $('[data-toggle="popover"]').popover();

          $('.popover-trigger.toplaag-popover').on('click', function (e) {
              $('.popover-trigger.toplaag-popover').not(this).popover('hide');
          });
          $('.popover-trigger.toplaag-popover').popover({
              content: function() {
                    return $(this).children('.popover-content').html();
              },
              template: '<div class="popover toplaag-popover-container" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body toplaag-popover-body"></div></div>',
              placement: 'top'
          });

          $('.popover-trigger.allowance-popover').on('click', function (e) {
              $('.popover-trigger.allowance-popover').not(this).popover('hide');
          });

          $('.popover-trigger.allowance-popover').popover({
              html: true,
              content: function() {
                  return $(this).children('.popover-content').html();
              },
              template: '<div class="popover allowance-popover-container" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body allowance-popover-body"></div></div>',
              placement: 'top'
          });

          $('.popover-trigger.tonnage-popover').on('click', function (e) {
              $('.popover-trigger.tonnage-popover').not(this).popover('hide');
          });

          $('.popover-trigger.tonnage-popover').popover({
              html: true,
              content: function() {
                  return $(this).children('.popover-content').html();
              },
              template: '<div class="popover tonnage-popover-container" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body tonnage-popover-body"></div></div>',
              placement: 'top'
          });

          $('[data-toggle=search-form]').click(function() {
              $('.search-form-wrapper').toggleClass('open');
              $('.search-form-wrapper .search').focus();
              $('html').toggleClass('search-form-open');
          });
          $('[data-toggle=search-form-close]').click(function() {
              $('.search-form-wrapper').removeClass('open');
              $('html').removeClass('search-form-open');
          });
          $('.search-form-wrapper .search').keypress(function( event ) {
              if($(this).val() == "Search") $(this).val("");
          });
          $('.search-close').click(function(event) {
              $('.search-form-wrapper').removeClass('open');
              $('html').removeClass('search-form-open');
          });

          $(document).off('submit', '.woocommerce-cart-form').on('submit', '.woocommerce-cart-form', function(){
              return true;
          });

          $('.shipping-calculator-form #calc_shipping_country').on('change', function(){
                if($(this).val() === 'BE') {
                    $('#calc_shipping_city_field_2, #calc_shipping_postcode_field, #calc_shipping_address_1_field, #calc_shipping_address_2_field').show();
                } else {
                    $('#calc_shipping_postcode_field, #calc_shipping_address_2_field').show();
                    $('#calc_shipping_city_field_2, #calc_shipping_address_1_field').hide();
                }
          });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
      // About us page, note the change from about-us to about_us.
      'afrekenen': {
          init: function() {
              //checkVatRequired();

              var countryId = $('select[name="billing_country"]').val();
              $('select[name="billing_country"]').on('change', function() {
                  //checkVatRequired();
                  if(countryId !== $('select[name="billing_country"]').val()) {
                      $('input[name="billing_address_1"], input[name="billing_address_2"], input[name="billing_postcode"], input[name="billing_city"]').val('');
                  }

              });

              $('input[name="billing_company"]').on('keyup', function() {
                  //checkVatRequired();
              });

              function checkVatRequired()
              {
                  var baseCountry = 'NL';
                  var vatInput = $('input[name="vat_number"]');
                  var vatLabel = $('label[for="vat_number"]');
                  var companyInput = $('input[name="billing_company"]');


                  vatInput.attr('required', '');
                  vatLabel.find('abbr').remove();
                  vatLabel.find('span').remove();
                  vatLabel.append('<span class="optional">(optioneel)</span>');

                  if($('select[name="billing_country"]').val() !== baseCountry && companyInput.val() !== '') {
                      vatInput.attr('required', 'required');
                      vatLabel.append('<abbr class="required" title="verplicht">*</abbr>');
                      vatLabel.find('span').remove();
                  }
              }
          }
      }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
